import { syncContribution } from './sync.js';
import { nanoid } from 'nanoid';
import { store } from './vue.js';

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const actions = {
  initial: 'normal',
  neutral: 'guard',
  good: 'hero',
  evil: 'villain',
};

const labels = {
  vk: 'vk',
  twitter: 'twi',
  facebook: 'fb',
};

const shareTexts = {
  initial: 'Устали от 2020? \n' + 'Приближайте или отдаляйте Новый год',
  neutral: 'Стою на страже Нового года \n' + 'Не шучу шутки со временем',
  good: 'Скорее бы 2020 закончился! \n' + 'Я герой и приближаю конец года',
  evil: 'Пусть 2020 длится вечно! \n' + 'Я злодей и отдаляю Новый год',
}

const shareables = {
  vk: (link, title) => `https://vk.com/share.php?url=${link}&title=${title}`,
  twitter: (link, title) => `https://twitter.com/intent/tweet?text=${title}&url=${link}`,
  facebook: (link, title) => `https://www.facebook.com/sharer.php?src=sp&u=${link}&title=${title}`,
}

const buttons = Array.from(document.querySelectorAll('[data-share]'));

buttons.forEach(button => {
  button.addEventListener('click', (event) => {
    const type = button.dataset.share;
    const role = store.getters.role;
    const shareLink = `https://ny.habr.com/users/${nanoid(10)}/${getCookie('cred')}`;
    let windowContext = window.open();
    function requestShare() {
      windowContext.location = shareables[type](shareLink, shareTexts[role]);
    }
    syncContribution().then(requestShare, requestShare);
    window.gtag('event', 'share', {
      [labels[type]]: actions[role],
    });
  })
});

const nyShareTexts = {
  evil: 'Злодеи победили! \n' + 'Но 2021 все равно наступил',
  neutral: 'Ура, 2020 закончился! \n' + 'И закончился вовремя',
  good: 'Ура, 2020 закончился! \n' + 'Герои приблизили конец года',
};

const nyTypes = {
  vk: 'vk',
  facebook: 'fb',
  twitter: 'tw',
}

const buttonsNy = Array.from(document.querySelectorAll('[data-share-ny]'));

buttonsNy.forEach(button => {
  button.addEventListener('click', (event) => {
    const type = button.dataset.shareNy;
    const contribution = store.state.totalContribution;
    let result = 'neutral';
    if (contribution > 0) {
      result = 'good';
    } else if (contribution < 0) {
      result = 'evil';
    }
    const text = nyShareTexts[result];
    window.open(shareables[type](`https://ny.habr.com/share/${nanoid(10)}/new-year`, text), '_blank');
    window.gtag('event', 'share_afterny', {
      social: nyTypes[result],
    });
  });
});