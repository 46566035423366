export { default as throttle } from 'lodash.throttle';

export function hide(ids) {
  ids.forEach(id => {
    Array.from(document.querySelectorAll(`[data-${id}]`)).forEach(el => {
      el.setAttribute('hidden', 'hidden');
      el.dataset[id] = '';
    });
  });
}

export function show(ids) {
  ids.forEach(id => {
    Array.from(document.querySelectorAll(`[data-${id}]`)).forEach(el => {
      el.removeAttribute('hidden');
      el.dataset[id] = 'on';
    });
  });
}