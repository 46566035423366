import Vue from 'vue';
import Vuex from 'vuex';
import { destroyMarquee, initMarquee } from './marquee.js';
import storeConfig from './store.js';
import Contribution from './Contribution.vue';
import { updateSwipers } from './swiper.js';
import Timer from './Timer.vue';
import { hide, show } from './utils.js';

Vue.use(Vuex);

export const store = new Vuex.Store(storeConfig);

new Vue({
  store,
  el: '[data-contribution]',
  render(h) {
    return h(Contribution);
  }
});

new Vue({
  store,
  el: '[data-countdown]',
  render(h) {
    const { newYearDate, isNewYear } = this.$store.getters;
    return h(Timer, {
      props: {
        time: isNewYear ? 0 : newYearDate,
        duration: isNewYear,
      }
    });
  }
});

new Vue({
  store,
  el: '[data-contribution-timer]',
  render(h) {
    const { myContribution } = this.$store.state;
    const { isNewYear } = this.$store.getters;
    if (isNewYear) return null;
    return h(Timer, {
      props: {
        time: myContribution,
        duration: true,
      }
    });
  }
});

new Vue({
  store,
  el: '[data-total-contribution]',
  render(h) {
    const { totalContribution } = this.$store.state;
    return h(Timer, {
      props: {
        time: totalContribution,
        duration: true,
      }
    });
  }
});

function handleContribution(totalContribution, lastContribution) {
  if (totalContribution === 0) {
    hide(['further', 'closer']);
    show(['still']);
  } else if (totalContribution > 0) {
    hide(['further', 'still']);
    show(['closer']);
  } else {
    hide(['closer', 'still']);
    show(['further']);
  }
}

handleContribution(store.state.totalContribution);

store.watch((state, getters) => {
  return state.totalContribution;
}, handleContribution);

const roles = ['good', 'evil', 'neutral', 'initial'];
const goodRoles = ['good', 'neutral', 'initial'];

function handleRole(role, prevRole) {
  const shouldUpdateMarquee = !goodRoles.includes(role) || !goodRoles.includes(prevRole);
  if (shouldUpdateMarquee) {
    destroyMarquee();
  }
  hide(roles.filter(r => r !== role));
  show([role]);
  updateSwipers();
  if (shouldUpdateMarquee) {
    initMarquee();
  }
}

initMarquee();
handleRole(store.getters.role, 'initial');

store.watch((state, getters) => {
  return getters.role;
}, handleRole);

store.watch((state, getters) => {
  return getters.isNewYear;
}, function() {
  hide(['notnewyear']);
  show(['newyear']);
});