import { throttle } from './utils.js';
import { store } from './vue.js';
import axios from 'axios';

axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

let initAttempts = 0;

function init() {
  window.grecaptcha.ready(function() {
    window.grecaptcha.execute('6LeSG_kZAAAAAAN226flScY2HqjYHd-2OidwtgQH', { action: 'submit' })
      .then(function(token) {
        axios.get('/users/init', { params: { token } })
          .then(({ data }) => {
            const {
              error,
            } = data;
            if (error) {
              initAttempts++;
              setTimeout(init, 1000 + 1000 * initAttempts);
            } else {
              initAttempts = 0;
              onInit(data);
            }
          });
      })
      .catch(() => {
        initAttempts++;
        setTimeout(init, 1000 + 1000 * initAttempts);
      });
  });
}

init();

let shouldSendContribution = false;
let lastClicked = 0;

function onInit(data) {
  const {
    shift: totalContribution,
    balance: myContribution,
    clicks: totalClicks,
  } = data;
  store.replaceState({
    totalContribution,
    myContribution,
    totalClicks,
    hasReplacedState: true,
    dateNow: Date.now(),
    nyCode: null,
    overheat: 0,
    interval: null,
  });
  pollTotalContribution();
  store.watch((state, getters) => {
    return state.myContribution;
  }, (role, prevRole) => {
    shouldSendContribution = true;
    lastClicked = Date.now();
  });
  setInterval(() => {
    if (shouldSendContribution) syncContribution();
    shouldSendContribution = false;
  }, 2000);
  setInterval(() => {
    store.commit('setDateNow', Date.now());
  }, 500);
}

let pollRequest;
let failedAttempts = 0;

function pollTotalContribution() {
  if (store.getters.isNewYear) return;
  let timeout = 2000;
  if (Date.now() - lastClicked > 5000) {
    timeout += 6000;
  }
  if (failedAttempts) timeout += 4000 * failedAttempts;
  if (pollRequest || document.visibilityState === 'hidden') {
    setTimeout(pollTotalContribution, timeout);
  } else {
    setTimeout(() => {
      pollRequest = axios.get('/general').then(({ data }) => {
        store.commit('setTotalContribution', data.shift);
        pollTotalContribution();
        pollRequest = null;
        failedAttempts = 0;
      }).catch(() => {
        pollTotalContribution();
        pollRequest = null;
        failedAttempts++;
      });
    }, timeout);
  }
}

let contribRequest;

export function syncContribution() {
  const contribution = store.state.myContribution;
  const clicks = store.state.totalClicks;
  if (contribRequest) return contribRequest;
  contribRequest = axios.post('/new-year/set', {
    shift: contribution,
    clicks,
  }).then(({ data: { errorCode, balance, clicks } }) => {
    if (errorCode === 2) {
      store.dispatch('startOverheat');
      store.commit('setClicks', clicks);
      store.commit('setMyContribution', balance);
    }
    contribRequest = null;
  }).catch(() => {
    contribRequest = null;
  });
  return contribRequest;
}