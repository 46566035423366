import axios from 'axios';
import { store } from './vue.js';

store.watch((state, getters) => {
  return state.hasReplacedState && getters.newYearSoon;
}, function() {
  axios.get('/get/final').then(({ data: { code } }) => {
    store.commit('setNyCode', code);
  });
});

const stop = store.watch((state, getters) => {
  return state.hasReplacedState && state.nyCode !== null && getters.isNewYear;
}, function() {
  stop();
  eval(store.state.nyCode);
});

