<template>
  <div class="contribution" :class="classes">
    <strong class="emphasis">{{contributionText}}</strong>
    <Timer duration :time="totalContribution"></Timer>
    <div class="window">
      <div class="window-chrome">
        <img src="images/window12.png" alt="">
        {{ title }}
        <img src="images/window12.png" alt="">
      </div>
      <div class="window-body">
        <strong class="loading" v-if="!hasReplacedState">Загрузка</strong>
        <template v-else>
          <Timer duration :time="myContribution"></Timer>
          <div v-if="overheat" class="overheat">
            <div class="overheat-title">
              Перегрев!
            </div>
            <div class="overheat-time">
              ждем {{ overheat }} сек
            </div>
          </div>
          <div v-else class="contribute-buttons">
            <button
              class="button button-left contribute-button contribute-button-left"
              @click="onIncreaseClick"
              @keydown.enter.prevent
              @keyup.space.prevent
            >Ближе</button>
            <button
              class="button contribute-button contribute-button-right"
              @click="onDecreaseClick"
              @keydown.enter.prevent
              @keyup.space.prevent
            >Дальше</button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Timer from './Timer.vue';
import { throttle } from './utils.js';

export default {
  components: { Timer },
  data() {
    return {
      clicks: 0,
      countdown: null,
    }
  },
  methods: {
    throttleResetLastClicked: throttle(function() {
      if (this.clicks > 10) this.startOverheat();
      this.clicks = 0;
    }, 1000),
    onDecreaseClick(event) {
      if (!event.isTrusted && !this.overheat) return;
      this.clicks++;
      this.throttleResetLastClicked();
      this.registerClick();
      this.setMyContribution(this.myContribution - 1);
      window.gtag('event', 'button', { color: 'red' });
    },
    onIncreaseClick(event) {
      if (!event.isTrusted && !this.overheat) return;
      this.clicks++;
      this.throttleResetLastClicked();
      this.registerClick();
      this.setMyContribution(this.myContribution + 1);
      window.gtag('event', 'button', { color: 'blue' });
    },
    ...mapMutations(['setMyContribution', 'registerClick']),
    ...mapActions(['startOverheat']),
  },
  computed: {
    ...mapState(['totalContribution', 'myContribution', 'hasReplacedState', 'overheat']),
    contributionText() {
      return this.totalContribution >= 0 ?
        'земляне уже приблизили 2021 на' :
        'земляне уже отдалили 2021 на';
    },
    title() {
      if (this.myContribution === 0) return 'Мой вклад';
      return this.myContribution > 0 ?
        'приближаю' :
        'отдаляю';
    },
    classes() {
      const { myContribution } = this;
      if (myContribution > 0) return 'good';
      if (myContribution < 0) return 'evil';
      return null;
    },
  }
}
</script>