import $ from 'jquery';
window.$ = window.jQuery = $;
require('jquery.marquee');

$('.headline-text').marquee({
  duration: 28000,
  direction: 'left',
  startVisible: true,
  duplicated: true,
});

export function destroyMarquee() {
  $('.scroll-text-left').marquee('destroy');

  $('.scroll-text-right').marquee('destroy');
}

export function initMarquee() {
  $('.scroll-text-left').marquee({
    duration: 6000,
    direction: 'left',
    startVisible: true,
    duplicated: true,
  });

  $('.scroll-text-right').marquee({
    duration: 6000,
    direction: 'right',
    startVisible: true,
    duplicated: true,
  });
}