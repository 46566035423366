<template>
  <div class="timer">
    <div class="timer-flex-fix"></div>
    <template v-if="days">
      <div class="timer-column">
        <div class="timer-column-title">{{daysString}}</div>
        <div class="timer-column-value">дн</div>
      </div>
      <div class="timer-spacer-empty"></div>
    </template>
    <template v-if="days || hours">
      <div class="timer-column">
        <div class="timer-column-title">{{hoursString}}</div>
        <div class="timer-column-value">час</div>
      </div>
      <div class="timer-spacer"></div>
    </template>
    <template v-if="days || hours || minutes">
      <div class="timer-column">
        <div class="timer-column-title">{{minutesString}}</div>
        <div class="timer-column-value">мин</div>
      </div>
      <div class="timer-spacer"></div>
    </template>
    <template v-if="days || hours || minutes || seconds">
      <div class="timer-column">
        <div class="timer-column-title">{{secondsString}}</div>
        <div class="timer-column-value">сек</div>
      </div>
      <div class="timer-spacer"></div>
    </template>
    <div class="timer-column">
      <div class="timer-column-title">{{millisecondsString}}</div>
      <div class="timer-column-value">миллисек</div>
    </div>
    <div class="timer-flex-fix"></div>
  </div>
</template>

<script>
const pad = (number, padding = 2) => {
  number = number.toString().replace('-', '');
  return number.indexOf('-') !== -1 ?
    `-${number.slice(1).padStart(padding, '0')}` :
    number.padStart(padding, '0');
}

export default {
  props: {
    time: Number,
    duration: Boolean,
  },
  data() {
    return {
      dateNow: Date.now(),
    }
  },
  mounted() {
    if (this.duration) return;
    const updateNow = () => {
      this.dateNow = Date.now();
      requestAnimationFrame(updateNow);
    };
    updateNow();
  },
  computed: {
    delta() {
      const now = this.duration ? 0 : this.dateNow;
      return this.time - now;
    },
    base() {
      const op = this.delta < 0 ? Math.ceil : Math.floor;
      const milliseconds = op(this.delta);
      const seconds = op(milliseconds/1000);
      const minutes = op(seconds/60);
      const hours = op(minutes/60);
      return {
        milliseconds,
        seconds,
        minutes,
        hours,
      }
    },
    days() {
      return Math.floor(this.base.hours/24);
    },
    hours() {
      return this.base.hours-(this.days*24);
    },
    minutes() {
      return this.base.minutes-(this.days*24*60)-(this.hours*60);
    },
    seconds() {
      return this.base.seconds-(this.days*24*60*60)-(this.hours*60*60)-(this.minutes*60);
    },
    milliseconds() {
      return this.base.milliseconds-(this.days*24*60*60*1000)-(this.hours*60*60*1000)-(this.minutes*60*1000)-(this.seconds*1000);
    },
    daysString() {
      return pad(this.days);
    },
    hoursString() {
      const res = this.hours;
      return this.days ? pad(Math.abs(res)) : pad(res);
    },
    minutesString() {
      const res = this.minutes;
      return (this.days || this.hours) ? pad(Math.abs(res)) : pad(res);
    },
    secondsString() {
      const res = this.seconds;
      return (this.days || this.hours || this. minutes) ? pad(Math.abs(res)) : pad(res);
    },
    millisecondsString() {
      const res = this.milliseconds;
      return (this.days || this.hours || this. minutes || this.seconds) ? pad(Math.abs(res), 3) : pad(res, 3);
    },
  }
}
</script>

<style>
.timer {
  display: flex;
  text-align: center;
  overflow: auto;
}

.timer-flex-fix {
  width: 8px;
  flex: none;
}

.timer-flex-fix:first-child {
  margin-left: auto;
}

.timer-flex-fix:last-child {
  margin-right: auto;
}

.timer-column {
  flex: none;
}

.timer-column-title {
  font-weight: 900;
  font-size: 38px;
  line-height: 42px;
}

.timer-column-value {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}

.timer-spacer {
  flex: none;
  position: relative;
  margin: 20px 4px 0;
  height: 4px;
  width: 4px;
  box-shadow: 0 -4px 0 0 #fff, 0 4px 0 0 #fff;
}

.timer-spacer-empty {
  flex: none;
  width: 20px;
}
</style>