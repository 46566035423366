import { Navigation, Swiper } from 'swiper';
import 'swiper/swiper-bundle.min.css';

Swiper.use([Navigation]);

const swipers = [];

export function updateSwipers() {
  swipers.forEach(swiper => {
    swiper.update();
  });
}

Array.from(document.querySelectorAll('.swiper-container')).forEach(el => {
  swipers.push(
    new Swiper(el, {
      loop: true,
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
      },
    })
  );
});

document.querySelector('#swiper-buy').addEventListener('click', event => {
  const slide = document.querySelector('.swiper-container:not([hidden]) .swiper-slide-active .slide');
  const url = slide.dataset.href;
  window.open(url, '_blank');
});