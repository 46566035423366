// import music1 from './music/1.mp3';
import music2 from './music/2.mp3';
// import music3 from './music/3.mp3';
import music4 from './music/4.mp3';
// import music5 from './music/5.mp3';
// import music6 from './music/6.mp3';
// import music7 from './music/7.mp3';
import music8 from './music/8.mp3';
import { hide, show } from './utils.js';
// import music9 from './music/9.mp3';
// import music10 from './music/10.mp3';

import { store } from './vue.js';

let currentSong;

const songs = {
  neutral: new Audio(music4),
  good: new Audio(music2),
  evil: new Audio(music8),
}

store.watch((state, getters) => {
  return getters.role;
}, (role, prevRole) => {
  if (role !== prevRole && currentSong) {
    playMusic(songs[role]);
  }
});

function playMusic(music) {
  stopMusic();
  currentSong = music;
  music.play();
  music.addEventListener('ended', playMusic);
}

function stopMusic() {
  if (!currentSong) return;
  currentSong.pause();
  currentSong.currentTime = 0;
  currentSong = null;
}

document.querySelector('.music-button').addEventListener('click', event => {
  if (currentSong) {
    stopMusic();
    hide(['musicon']);
    show(['musicoff']);
  } else {
    hide(['musicoff']);
    show(['musicon']);
    const role = store.getters.role;
    playMusic(songs[role]);
  }
});