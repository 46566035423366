export default {
  state() {
    return {
      totalContribution: 0,
      myContribution: 0,
      totalClicks: 0,
      hasReplacedState: false,
      dateNow: Date.now(),
      nyCode: null,
      overheat: 0,
      interval: null,
    }
  },
  mutations: {
    setTotalContribution(state, contrib) {
      state.totalContribution = contrib;
    },
    setMyContribution(state, contrib) {
      state.myContribution = contrib;
    },
    registerClick(state) {
      state.totalClicks++;
    },
    setClicksBuffer(state, buffer) {
      state.clicksBuffer = buffer;
    },
    setDateNow(state, date) {
      state.dateNow = date;
    },
    setNyCode(state, code) {
      state.nyCode = code;
    },
    setOverheat(state, value) {
      state.overheat = value;
    },
    setInterval(state, interval) {
      clearInterval(state.interval);
      state.interval = interval;
    },
  },
  actions: {
    startOverheat({ state, commit }) {
      commit('setOverheat', 12);
      commit('setInterval', setInterval(() => {
        if (state.overheat === 0) {
          commit('setInterval', null);
          return;
        }
        commit('setOverheat', state.overheat - 1);
      }, 1000));
    },
  },
  getters: {
    newYearDate(state) {
      return 1609448400000 - state.totalContribution;
    },
    role(state) {
      const { myContribution, hasReplacedState } = state;
      if (!hasReplacedState) return 'initial';
      if (myContribution === 0) return 'neutral';
      return myContribution > 0 ? 'good' : 'evil';
    },
    newYearSoon(state, getters) {
      if (!state.hasReplacedState) return false;
      return getters.newYearDate - (state.dateNow + 3600000) <= 0;
    },
    isNewYear(state, getters) {
      if (!state.hasReplacedState) return false;
      return getters.newYearDate - state.dateNow <= 0;
    },
  }
}